<template>
        <canv/>
    <img class="mei" src="/assets//meinv.png" >
    <div class="jiaZai" :style=enter1>
        <div class="paoBu" >
            <div class="wenZi" :style=enter2 @click="enterIn" ref="wz"></div>
            <div class="enter"></div>
        </div>
    </div>
        <div class="meC">
            <div class="me">
                <div class="hy hy1" ref="hy1"></div>
                <div class="hy hy2" ref="hy2"></div>
                <div class="hy hy3" ref="hy3"></div>
                <div class="hy hy4" ref="hy4"></div>
                
                <audio id="myAudio" src="/assets/yinyue.mp3" ref="aud" @canplay="cp"></audio>
            </div>
            <div class="footTop"></div>
            <div class="foot">
                <a class="a2" href="https://beian.miit.gov.cn/" target="_blank">黔ICP备2024032689号-1</a>
                <img class="im" src="/assets/beian.png">
                <a class="a1" href="https://beian.mps.gov.cn/#/query/webSearch?code=52050002000116" rel="noreferrer" target="_blank">贵公网安备52050002000116</a>     
            </div>
        </div>
        
</template>
<script>
    import contentRight from './contentRight';
    import contentLeft from './contentLeft';
    import contentItems from './contentItems';
    import { mapState, mapActions } from 'vuex';
    import topB from "./topB.vue";
    import canv from "./canv.vue";
    import axios from 'axios';

    var ip="introl-whc.cn";//localhost:8000  introl-whc.cn
    export default {
        name: 'me',
        data() {
        return {
            t1: "欢迎来到鑫可可的网站！",
            t2: "我是一个崇尚技术知识，热爱技术和创新的开发者",
            t3: "在这里我会定期更新我的最新项目、技术文章以及学习心得",
            t4: "如果您有任何问题或者建议，欢迎随时与我联系沟通!",
            i1: 0, i2: 0, i3: 0, i4: 0,
            cur:1,
            interval:null,
            loding: '',
            wenZi: "加载中...",
            wzidx:0,
            enter1:{
                display:"",
            },
            enter2:{
                display:"",
            },
            interval2:null,
            showinter:null,
            canp: false,
        };
        
    },
    async mounted() { 
 
        if(!this.firstEnter){
            this.enter1.display="none";
            this.typeText(this.$refs.hy1, this.t1,'i1');
            this.$refs.aud.play(); 
        }
        this.interval2=setInterval(() => {
            if(this.wzidx==5) {
                this.wzidx=0;
                this.$refs.wz.innerText="";
            }
            this.$refs.wz.innerText+=this.wenZi[this.wzidx];
            this.wzidx++;
        }, 300);
        this.showinter=setInterval(() => {
            this.show();
        }, 4000);        
        
        let res=await axios.get(`https://${ip}/xinkeke/liuyanAll`, {
        });     
    },
    beforeUnmount(){
        clearInterval(this.interval);
        clearInterval(this.interval2);
        clearInterval(this.showinter);
    },
    computed:{
        ...mapState(['firstEnter']),
    },
    methods: {
        ...mapActions(['updateFirstEnter']),
        cp(){
            this.canp=true;
        },
        enterIn(){
            if(this.$refs.wz.innerText=="加载中..."){
                return;
            }
            this.enter1.display="none";
            this.updateFirstEnter(false);
            this.typeText(this.$refs.hy1, this.t1,'i1');
            this.$refs.aud.play(); 
        },
        show(){
            if(!this.canp) return;
            clearInterval(this.interval2);
            this.enter2.cursor="pointer";   
            this.$refs.wz.innerText="点我进入";     
        },
        typeText(element, text, indexKey) {
            this.interval = setInterval(() => {
                    if (this[indexKey] < text.length) {
                        element.innerText += text[this[indexKey]++];
                    } 
                    else {
                        clearInterval(this.interval);
                        if( this.cur<4) {
                            this.cur+=1;
                            if(this.cur==2) this.typeText(this.$refs.hy2,this.t2,'i2');
                            if(this.cur==3) this.typeText(this.$refs.hy3, this.t3,'i3');
                            if(this.cur==4) this.typeText(this.$refs.hy4, this.t4,'i4');
                        }
                        else{
                            this.$refs.aud.pause();
                        }
                        
                    }
                
            }, 100);
        }
    },
    components:{
        contentLeft,
        contentRight,
        contentItems,
        canv,topB,
    }
    }
</script>

<style scoped>
    @font-face {
        font-family: 'myFont1';
        src: url(/public/assets/myFont1.ttf) format('truetype');
    }
    .jiaZai{
        width:100vw;
        height:100vh;
        position:fixed;
        background-color:black;
        z-index:10;
        .paoBu{
            width:15vw;
            height:15vw;
            background-image:url("/public/assets/paobu.gif");
            background-position:center;
            background-size:contain;
            position:relative;
            left: 40vw;
            top:25vh;
        }
        .wenZi{
            position:absolute;
            left:40%;
            top:80%;
            font-size:1vw;
        }
    }
    .lod{
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 3000;
        background-color: black;
        color: white;
        display: none;
    }
    .lod div{
        margin-left: 43vw;
        margin-top: 45vh; 
        font-size: 2vh;
    }
    .mei{
        width: 30vw;
        height: 35vw;
        background-image: url("/public/assets/meinv.png");
        background-size:contain;     
        background-position: center;
        background-repeat: no-repeat; 
        position: fixed;
        right: 0vw;
        bottom:1vh;
        z-index:2;
    }
    .hy{
        position: absolute;
        left: 4vw;
    }
    .hy1{
        top: 20vh;
    }
    .hy2{
        top: 27vh;
    }
    .hy3{
        top: 34vh;
    }
    .hy4{
        top: 41vh;
    }
    .me{
        font-size: 3.5vh;
        width: 100vw;
        height: 94vh;
        position: relative;
    }
    .meC{
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        position: fixed;
        margin-top: 6vh;
    }
    .meC::-webkit-scrollbar {
        width: 0.3vw; 
        background-color: #f5f5f5;   
        
    }
    .meC::-webkit-scrollbar-track {
        background-color: #f5f5f5; 
    }
    .meC::-webkit-scrollbar-thumb {
        background-color: #888; 
        border-radius: 5vh; 
    }
    .meC::-webkit-scrollbar-button {
        display: none; 
    }    
    .footTop{
        width: 100vw;
        height: 5vh;
    }
    .foot{
        width: 100vw;
        height: 16vh;
        background-color: rgba(0,0,0,0);
        z-index: 100;
        display:flex;
        justify-content: center;
        line-height: 6vh;
        vertical-align: bottom;
        flex-wrap: wrap;
        align-content: flex-start;
        .im{
            display: block;
            margin-right: 1vw;
        }
        .a1,.a2{
            display: inline-block;
            height: 3vh;
            text-align: center;
            line-height: 3vh;
            font-size: 2vh;
            color: #ffa200;
            margin-left: 2vw;
        }
        .a2{
            width: 100vw;   
            margin-left: 1vw;
            margin-top: 1vh;
        }
        .a1{
            margin-left: 0vw;
            margin-top: 1vh;
        }
    }        

</style>